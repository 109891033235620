body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url(/static/media/Montserrat-Bold.ade91f47.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url(/static/media/Montserrat-SemiBold.c641dbee.ttf) format("truetype");
  font-weight: bold;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.flags-right-aligned {
  padding-right: 120px !important;
}


.flag-select .flag-option {
  margin: 0;
}

.nav-wrapper {
  background-color: white !important;
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;
  margin-right: -5px;
  margin-left: -5px;
}

.image-w-100 {
  width: 100%;
  display: block !important;
}

.image-flag {
  width: 25px !important;
}

@media only screen and (min-width: 1170px) {
  .banner {
    height: 70px !important;
  }
}

@media only screen and (min-width: 500px) {
  .login {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 600px) {
  .login {
    margin-top: 10px !important;
  }
}


.pb-5,
.py-5 {
  padding-bottom: 0.3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 0.3rem !important;
}

.imageNotMargings {
  margin: -1px;
}

.imageNotMargingsFooter {
  margin: -40px;
  margin-top: 50px !important;
}

.mobile-image {
  display: visible;
}

@media only screen and (max-width: 768px) {
  .mobile-image {
    display: none;
  }
}

.noppading {
  padding: 0px !important;
}

.logotop {
  vertical-align: middle;
}

.textoJustificado {
  text-align: justify;
  text-justify: inter-word;
}

.ElementoIzquierda {
  box-align: left;
}

.font-face-mb {
  font-family: "MontserratBold";
}

.font-face-msb {
  font-family: "MontserratSemiBold";
}

body {
  font-family: "MontserratSemiBold" !important;
}


.padded {
  padding-left: 5px;
  padding-right: 30px;
}

.paddingtopflags {
  padding-top: 2rem !important;
}

#block_container {
  display: flex;
  justify-content: center;
}

.mobileShow {
  display: none;
}

.desktopShow {
  display: inline;
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .mobileShow {
    display: inline;
  }

  .desktopShow {
    display: none;
  }

  .paddingButtons {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

/* Desktop */
@media only screen and (min-device-width : 481px) {
  .mobileShow {
    display: none;
  }

  .desktopShow {
    display: inline;
  }

  .contentaligned {
    /* 25% of viewport */
    height: 80vh;
    width: 15rem;
    padding: 2rem;
  }

  .paddingButtons {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .divSpaces{
    padding-top: 5em;
  }

}

@media (max-width:480px) {

  h1,
  .h1 {
    font-size: 30px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 26px;
  }

  h4,
  .h4 {
    font-size: 24px;
  }

  h5,
  .h5 {
    font-size: 20px;
  }

  .divSpaces{
    padding-top: 2em;
  }
}



.buttonBackgroundColor {
  background-color: #5847B2;
  color: white;
}

.buttonSoporteBackgroundColor {
  background-color: #5847B2;
  color: white;
}

.linkColor {
  color: #5847B2;
  font-weight: bold;
}

.btn:hover, .btn-large:hover {
  background-color: #5847B2 !important;
  }

/*
.btn {
  text-transform: unset !important;
}
*/

.select {
  font-family: unset !important;
}

.titulo {
  color: #3e3d40;
}

.subTitulo {
  color: #3e3d40;
}

.imagen {
  max-height: 99.4vh;
}

.nomar {
  margin-bottom: 0
}

#footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  /* Height of the footer */
}

.footermargin {
  margin: 1px !important;
}

.overflowDiv {
  overflow: auto;
}



.center {
  text-align: center;
}
